body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-nav {
  text-decoration: none;
}

.main-nav .nav-link {
  color:white !important;
}

.main-nav:hover .nav-link {
  color:#FFC107 !important;
}

.main-nav-active .nav-link {
  color: #FFC107 !important;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.modal-header {
  border-bottom: 0 none;
}

.search {
  position: absolute;
  top: 1rem;
  left: 13rem;
  transform: translateX(-50%);
  width: 100%;
  height: 48px;
  max-width: 392px;
  z-index: 10;
  margin-top: 90px;
  padding: 0.5rem;
  border-radius: 8px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%);
}

.search input {
  border: none;
  font-size: 1rem;
  width: 100%;
  padding-left: 10px;
  padding-top: 4px;
}

.focus-visible {
  outline: none;
}

.locate {
  position: absolute;
  bottom: 8.5rem;
  right: .02rem;
  background: none;
  border: none;
  z-index: 10;
  margin-right: 10px;
  cursor:pointer;
  padding: 5px;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%);
}

.boxList {
  border: none;
  padding-top: 8px;
  border-radius: 2px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%);
}

#msgBox {
  visibility: hidden;
  position: absolute;
  bottom: 0rem;
  background: none;
  border: none;
  z-index: 10;
  margin-bottom: 80px;
  margin-left: 10px;
  padding: 1rem;
  padding-left: 1px;
  padding-right: 1px;
  width: 24.5rem;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%);
}

.dec {
  font-size: 8px;
  font-weight: 700;
  align-items: center;
  text-align: center;
}

#msgBox input {
  border: 0.5px;
  background-color: #F9F9FB;
  border-radius: 10px;
  padding: 5px;
  width: 22.5rem;
  margin: 1rem;
}

.chatBot {
  position: absolute;
  bottom: .0rem;
  left: .02rem;
  background: none;
  color: white;
  border: none;
  z-index: 10;
  margin-bottom: 30px;
  margin-left: 10px;
  cursor: pointer;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 50px;
  box-sizing: border-box;
  background-color:blue;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%), 0 -1px 0 rgb(0 0 0 / 2%);
}